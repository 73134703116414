import Vue from 'vue';
import Vuetify, { VBtn, VIcon, VCarousel, VCarouselItem, VApp, VDialog, VCard, VCardTitle, VCardText, VCardActions, VSpacer, VDivider, VRadioGroup, VRadio, VExpansionPanel, VExpansionPanelContent, VExpansionPanelHeader, VExpansionPanels } from 'vuetify/lib';
import es from 'vuetify/src/locale/es';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify, {
  components: {
    VBtn,
    VIcon,
    VCarousel,
    VCarouselItem,
    VApp,
    VDialog, 
    VCard, 
    VCardTitle, 
    VCardText, 
    VCardActions, 
    VSpacer,
    VDivider,
    VRadioGroup,
    VRadio,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanels,
  },
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#2B3967',
        secondary: '#333',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
