






















































import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import { onMounted, ref, reactive, computed } from '@vue/composition-api';
import { Debug } from '../../shared/utils/helpers';
import { data } from '../connectors/observable';
import { mdiAccount } from '@mdi/js';
import { vRequired, vEmail, vPhoneRules, config } from '@monkeyplus/monkey-validates-form';
export default Vue.extend({
  props: {
    info: Object,
    lang:{
      type:String,
      default:'es'
    }
  },
  setup(props, { emit }) {
    config.lang = props.lang as string;
    const contacto = computed(() => store.state.suscripcion);
    const i = props.info;
    const formContacto = ref(null);
    const valid = ref(false);
    const state: any = reactive({
      form: 'suscripcion',
      email: '',
    });

    const reglas = reactive({
      email: [vRequired, vEmail],
    });

    const respuesta = reactive({
      texto: '',
      type: 'error',
      visible: false,
      spinner: false,
    });

    const enviar = (e: Event) => {
      e.preventDefault();
      if ((formContacto.value as any).validate()) {
        respuesta.spinner = true;
        const formdata = new FormData();
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };
        for (const key in state) {
            formdata.append(key, state[key]);
        }
        axios.post('/assets/php/sendemail.php', formdata, config)
            .then((response) => {
                (formContacto.value as any).reset();
                respuesta.texto = response.data.mensaje;
                respuesta.type = 'success';
            }).catch((error) => {
                respuesta.texto = 'Error al enviar el mensaje / Error sending message';
                respuesta.type = 'error';
            }).finally(() => {
                respuesta.visible = true;
                respuesta.spinner = false;
            });
      }
    };

    return {
      i,
      enviar,
      state,
      reglas,
      valid,
      respuesta,
      contacto,
      formContacto,
    };
  },
});
