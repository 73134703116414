import Vue from 'vue';
import { onMounted, ref, computed } from '@vue/composition-api';
import { Debug } from '@/shared/utils/helpers';
import { data } from '../connectors/observable';

const CargaSection = Vue.extend({
    setup() {
        const listo = computed( () => {
            return data.listo;
        });
        return {
            listo,
        };
    },
});
export default new CargaSection({
    el: '#carga',
    delimiters: ['[{', '}]'],
});
