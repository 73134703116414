


































































import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import { onMounted, ref, reactive, computed } from '@vue/composition-api';
import { Debug } from '../../shared/utils/helpers';
import { data } from '../connectors/observable';
import { mdiAccount } from '@mdi/js';
import { vRequired, vEmail, vPhoneRules, config } from '@monkeyplus/monkey-validates-form';
export default Vue.extend({
  props: {
    info: Object,
    producto : String,
    lang:{
      type:String,
      default:'es'
    }
  },
  setup(props, { emit }) {
    config.lang = props.lang as string;
    const contacto = computed(() => store.state.contacto);
    const i = props.info;
    const formContacto = ref(null);
    const valid = ref(false);
    let state: any = reactive({
      form: 'trabaja',
      nombre: '',
      apellido: '',
      email: '',
      telefono: '',
      ciudad: '',
      area: '',
      mensaje: '',
      hoja : undefined,
    });

    const reglas = reactive({
      nombre: [vRequired],
      apellido: [vRequired],
      email: [vRequired, vEmail],
      telefono: [vRequired, vPhoneRules],
      ciudad: [vRequired],
      cargo: [vRequired],
      area: [vRequired],
      mensaje: [vRequired],
      hoja: [vRequired],
    });

    const lista = ref([
      'Departamento comercial / Commercial Department',
      'Contabilidad / Accounting',
      'Logística / Logistics',
      'Administración / Administration',
    ]);

    const respuesta = reactive({
      texto: '',
      type: 'error',
      visible: false,
      spinner: false,
    });

    const enviar = (e: Event) => {
      e.preventDefault();
      if ((formContacto.value as any).validate()) {
        respuesta.spinner = true;
        const formdata = new FormData();
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };
        for (const key in state) {
            formdata.append(key, state[key]);
        }
        formdata.append('hoja', state.hoja.value);
        console.log(formdata.get('hoja'));
        axios.post('/assets/php/sendemail.php', formdata, config)
            .then((response) => {
                console.log(formContacto.value);
                (formContacto.value as any).reset();
                respuesta.texto = response.data.mensaje;
                respuesta.type = 'success';
                
            }).catch((error) => {
              console.log(error);
                respuesta.texto = 'Error al enviar el mensaje / Error sending message';
                respuesta.type = 'error';
            }).finally(() => {
                respuesta.visible = true;
                respuesta.spinner = false;
            });
      }
    };

    return {
      i,
      enviar,
      state,
      reglas,
      valid,
      respuesta,
      contacto,
      formContacto,
      lista,
    };
  },
});
