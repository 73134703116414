import Vue from 'vue';
import { onMounted, computed, ref } from '@vue/composition-api';
import { data } from '../connectors/observable';
import vuetify from '../plugins/vuetify';
const FooterSection = Vue.extend({
  setup() {
    // onMounted(() => {
    //     Debug.log('Header mounted');
    // });
    const terms = ref(false);
    const toggTerms = () => {
      terms.value = !terms.value;      
    }
    return {
      terms,
      toggTerms,
    };
  },
});
export default new FooterSection({
  el: '#footer',
  vuetify,
  delimiters: ['[{', '}]'],
});
