import Vue from 'vue';
import { onMounted, computed } from '@vue/composition-api';
import { data } from '../connectors/observable';
const headerSection = Vue.extend({
  setup() {
    // onMounted(() => {
    //     Debug.log('Header mounted');
    // });
    const count = computed(() => data.counter);
    return {
      count,
    };
  },
});
export default new headerSection({
  el: '#header',
  delimiters: ['[{', '}]'],
});
