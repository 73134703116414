























































import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import { onMounted, ref, reactive, computed } from '@vue/composition-api';
import { Debug } from '../../shared/utils/helpers';
import { data } from '../connectors/observable';
import { mdiAccount } from '@mdi/js';
import { vRequired, vEmail, vPhoneRules, config } from '@monkeyplus/monkey-validates-form';
export default Vue.extend({
  props: {
    info: Object,
    producto : String,
    lang:{
      type:String,
      default:'es'
    }
  },
  setup(props, { emit }) {
    config.lang = props.lang as string;
    const capacitation = computed(() => store.state.capacitation);
    const i = props.info;
    const formCapacitation = ref(null);
    const valid = ref(false);
    const state: any = reactive({
      form: 'capacitation',
      nombre: '',
      apellido: '',
      email: '',
      telefono: '',
      pais: '',
      ciudad: '',
      empresa: '',
      cargo: '',
    });

    const reglas = reactive({
      nombre: [vRequired],
      apellido: [vRequired],
      email: [vRequired, vEmail],
      telefono: [vRequired, vPhoneRules],
      pais: [vRequired],
      ciudad: [vRequired],
      empresa: [vRequired],
      cargo: [vRequired],
    });

    const respuesta = reactive({
      texto: '',
      type: 'error',
      visible: false,
      spinner: false,
    });

    const enviar = (e: Event) => {
      e.preventDefault();
      if ((formCapacitation.value as any).validate()) {
        respuesta.spinner = true;
        const formdata = new FormData();
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };
        for (const key in state) {
            formdata.append(key, state[key]);
        }
        axios.post('https://tensortec.com/assets/php/sendemail.php', formdata, config)
            .then((response) => {
                (formCapacitation.value as any).reset();
                respuesta.texto = response.data.mensaje;
                respuesta.type = 'success';
            }).catch((error) => {
                respuesta.texto = 'Error al enviar el mensaje / Error sending message';
                respuesta.type = 'error';
            }).finally(() => {
                respuesta.visible = true;
                respuesta.spinner = false;
            });
      }
    };

    return {
      i,
      enviar,
      state,
      reglas,
      valid,
      respuesta,
      capacitation,
      formCapacitation,
    };
  },
});
